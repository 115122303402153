
@media (max-width:375px) {
    .TopHeader{
        width:375px;
    }
    .pdf-logo{
        width:50px;
        margin-left: 15px !important;
        float: left;
    }
    .TopHeaderTitle{
        width: 146.56px !important;
        padding: 1px;
        margin-left: 1px !important;
        float: left
    }
    .TopHeaderTitle span{
        font-size: 10px !important;
        text-align: left !important;
        
    }
    .QRcodeHeader{
        text-align: left !important;

    }
    .qrcodeImage1{
        display: block !important;
        width: 50px !important;
        height: 50px !important;
    }


    .pdf-logo img{
        width:75px;
    }
    .headeCenterText{
        font-size: 10px;
        text-align: center;
    }
    .TopHeaderTitle span{
        width: 247.56px !important;
        padding: 1px;
        text-align: center;
        font-size: 15px;
    }
    .qrcodeImage2{
        display: block;
    }
}
@media (max-width:1918px) {
    .pdf-logo{
        width:17%;
    }
    .qrcodeImage1{
        display: block;
    }
}
@media (max-width:1366px) {
    .pdf-logo{
        margin-left: 35px;
        width:17%;
        float: left;
    }
    .TopHeaderTitle{
        float: left;
        margin-left: 10px;
        width: 55%;

    }
    .TopHeaderTitle span{
        float: left;
        margin-left: 10px;
        font-weight: 800;
        font-family: 'Times New Roman', Times, serif;
        font-size: 17px;
        text-align: center;
    }
    .headeCenterText{
        width: 95%;
        text-align: center;
    }
    .QRcodeHeader{
        float: left;
        width: 21%;
    }
    .qrcodeImage1{
        display: inline-block;
        width: 21%;
    }
    .qrcodeImage2{
        display: none;
    }

}
@media (max-width:1918px) {
    .pdf-logo{
        margin-left: 35px;
        width:17%;
        float: left;
    }
    .TopHeaderTitle{
        float: left;
        margin-left: 10px;
        width: 55%;
        text-align: center;
    }
    .TopHeaderTitle span{
        float: left;
        margin-left: 10px;
        font-weight: 800;
        font-family: 'Times New Roman', Times, serif;
        font-size: 17px;
        width: 483.56px;
        text-align: center;
    }
    .headeCenterText{
        width: 95%;
        text-align: center;
    }
    .QRcodeHeader{
        float: left;
        width: 21%;
    }
    .qrcodeImage1{
        display: inline-block;
        width: 200px;
    }
    .qrcodeImage2{
        display: none;
    }

}

/* img.pdf-logo{
    width:100;
} */